<template>
  <v-card
    class="w-100"
  >
    <v-card-title class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <div class="ml-3">
          <div class="d-flex align-center">
            <span>{{ role.name }}</span>
          </div>
          <div style="font-size: smaller; text-align: left">
            display_name: {{ role.display_name }}<br>
            rid : {{ role.rid }}<br>
            description : {{ role.description }}<br>
            is_public : <span v-if="role.is_public">✅</span><span v-else>❌</span><br>
            is_user_role: <span v-if="role.is_user_role">✅</span><span v-else>❌</span><br>
            is_solution: <span v-if="role.is_solution">✅</span><span v-else>❌</span><br>
            icon: <span v-if="role.icon"><v-icon color="primary">{{ role.icon }}</v-icon></span>
          </div>
        </div>
      </div>
      <div
        style="display: flex; align-items: center;"
      >
        <EditRoleModal :role="role" />
        <v-btn
          icon
          class="text-dark shadow-none transparent"
          @click="deleteRole(role)"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </div>
    </v-card-title>
  </v-card>
</template>
<script>
import ViewAbstract from "@/views/Mixins/ViewAbstract.vue";
import OrgAbstract from "@/components/Home/Org/Mixins/OrgAbstract.vue";
import store from "@/store";
import EditRoleModal from "@/components/Home/Admin/Modals/EditRoleModal.vue";

export default {
  name: 'RoleCard',
  components: {EditRoleModal},
  mixins: [
    ViewAbstract,
    OrgAbstract
  ],
  props: {
    role: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {
    deleteRole() {
      this.$store.dispatch("admin/deleteRole", {params: {rid: this.role.rid}})
          .then(response => {
            this.registerToast(this.$toast.success(`Removed role "${this.role.name}" successfully !`, {timeout: 3000}))
          })
          .catch(error => {
            this.registerToast(this.$toast.error(`Failed to remove "${this.member.name}".`, {timeout: 3000}))
          })
    }
  }
}
</script>