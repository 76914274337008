var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.button && _vm.organization && !_vm.table)?_c('v-list-item',{on:{"click":_vm.openModal}},[(_vm.home)?_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-share-variant")])],1):_vm._e(),(!_vm.home)?_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-multiple-plus")])],1):_vm._e(),_c('v-list-item-content',[_vm._v(" Share ")])],1):_vm._e(),(_vm.table)?_c('v-list-item',{attrs:{"id":_vm.index + '-share'},on:{"click":function($event){$event.stopPropagation();return _vm.openModal($event)}}},[_c('b-tooltip',{attrs:{"target":_vm.index + '-share',"boundary":"scrollParent","boundary-padding":-30,"placement":"bottom","triggers":"hover"}},[_vm._v(" Share this workbook ")]),_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-account-multiple-plus ")])],1),_c('v-list-item-title',[_vm._v("Invite people")])],1):_vm._e(),(_vm.organization)?_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("People with access")]),_c('v-card-text',[_c('v-select',{staticClass:"breakdown-select",attrs:{"items":_vm.sharingModesList,"item-text":"text","item-value":"value","height":"36","menu-props":"offsetY","background-color":"white","outlined":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)],1)]}},(_vm.canBeOpen)?{key:"append-item",fn:function(){return [_c('v-list-item',{on:{"click":_vm.open}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-group-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Manage Team "),_c('v-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Open in new tab'),expression:"'Open in new tab'",modifiers:{"hover":true,"top":true}}],staticClass:"'open'",staticStyle:{"margin-left":"1%"},attrs:{"small":"","dense":""},on:{"click":function($event){$event.stopPropagation();return (function (e) { return _vm.open(e, true); })($event)}}},[_vm._v(" mdi-open-in-new ")])],1)],1)],1)]},proxy:true}:null],null,true),model:{value:(_vm.sharing_mode),callback:function ($$v) {_vm.sharing_mode=$$v},expression:"sharing_mode"}}),(_vm.sharing_mode === 'users')?_c('div',[_c('v-combobox',{staticStyle:{"margin-top":"20px"},attrs:{"items":_vm.formatedMembers,"item-value":"id","item-text":"email","rules":_vm.rules.uid,"chips":"","multiple":"","outlined":"","append-icon":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":"","label":"","outlined":""},on:{"click:close":function($event){_vm.removeEmail($event, _vm.shared_to.indexOf(item))}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(_vm.croppedAddress(item)))])])]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_vm._v(" Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to add address ")])]},proxy:true}],null,false,2176217694),model:{value:(_vm.shared_to),callback:function ($$v) {_vm.shared_to=$$v},expression:"shared_to"}})],1):_vm._e()],1),_c('v-card-actions',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"right","align-items":"center","padding":"0px 24px 20px"}},[_c('v-btn',{staticClass:"shared-flow-btn bg-primary",attrs:{"loading":_vm.sharedFlowBusy},on:{"click":_vm.sendSharedFlow}},[(_vm.sharing_mode==='not-shared')?_c('span',[_vm._v("Ok")]):_c('span',[_vm._v("Share")])]),_c('v-btn',{on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }