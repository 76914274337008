<template>
  <v-card class="w-100">
    <v-card-title class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <OrgAvatar
          :editable="false"
          :size="60"
          :org="org"
        />
        <div class="ml-3">
          <div class="d-flex align-center">
            <span>{{ org.name }}</span>
          </div>
          <div style="font-size: smaller; text-align: left">
            oid : {{ org.oid }}<br>
            creation date: {{ new Date(org.created_at).toLocaleDateString() }}
          </div>
        </div>
      </div>
      <div style="display: flex; align-items: center;">
        <ManageTeamModal
          :org="org"
          edit
        />
        <DeleteOrgModal
          :org="org"
          admin-panel
        />
      </div>
    </v-card-title>
  </v-card>
</template>
<script>
import ManageTeamModal from "@/components/Home/Admin/Modals/ManageTeamModal.vue";
import OrgAvatar from "@/components/Home/Org/Modals/OrgAvatar.vue";
import ViewAbstract from "@/views/Mixins/ViewAbstract.vue";
import OrgAbstract from "@/components/Home/Org/Mixins/OrgAbstract.vue";
import jwt from "jsonwebtoken";
import DeleteOrgModal from "@/components/Home/Org/Modals/DeleteOrgModal.vue";

export default {
  name: 'OrganizationCard',
  components: {DeleteOrgModal, OrgAvatar, ManageTeamModal},
  mixins: [
    ViewAbstract,
    OrgAbstract
  ],
  props: {
    org: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      search: '',
      deleteOrgBusy: false
    };
  },
  computed: {},
  methods: {}
}
</script>