<template>
  <div>
    <v-btn
      text
      class="create-role-btn"
      @click="openModal"
    >
      <v-icon class="mr-1 create-role-icon">
        mdi-plus-box-outline
      </v-icon>
      <span class="create-role-text">Create Role</span>
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card
        class="datama-dialog"
      >
        <v-card-title>
          <span>
            Create Role
          </span>
          <v-icon
            class="transparent shadow-none"
            @click="closeModal"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="name"
            label="Role name"
            class="mb-8"
            hide-details
          />
          <v-text-field
            v-model="display_name"
            label="Display name"
            class="mb-8"
            hide-details
          />
          <v-text-field
            v-model="description"
            label="Usage"
            class="mb-8"
            hide-details
          />
          <v-text-field
            v-model="icon"
            label="Icon"
            class="mb-8"
            hide-details
          />
          <v-switch
            v-model="is_public"
            label="Public"
          />
          <v-switch
            v-model="is_user_role"
            label="For User"
          />
          <v-switch
            v-model="is_solution"
            label="Is Solution"
          />
          <InfoMessage
            icon="mdi mdi-list-box-outline"
            message="Create role to give access to users, members and organizations."
            styles="color:grey;display:block;line-height: 20px;font-size: 14px;"
            class="mb-4 text-left"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            class="mr-2 shadow-none transparent"
            @click="closeModal"
          >
            Cancel
          </v-btn>
          <v-btn
            class="bg-primary shadow-none"
            :loading="createRoleBusy"
            @click="createRole"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";
import OrgAbstract from "../../Org/Mixins/OrgAbstract.vue";
import AdminAbstract from "../Mixins/AdminAbstract.vue";
import InfoMessage from "@/components/Core/Structure/Misc/InfoMessage.vue";

export default {
  name: 'CreateRoleModal',
  components: {InfoMessage},
  mixins: [
    DataMaLogicAbstract, OrgAbstract, AdminAbstract

  ],
  data() {
    return {
      name: null,
      display_name: null,
      description: null,
      icon: null,
      is_user_role: true,
      is_public: false,
      is_solution: false,
      dialog: false,
      search: '',
      createRoleBusy: false
    };
  },
  methods: {
    openModal() {
      this.dialog = true;
    },
    closeModal() {
      this.name = null;
      this.display_name = null;
      this.description = null;
      this.icon = null;
      this.is_user_role = true;
      this.is_public = false;
      this.is_solution = false;
      this.dialog = false;
    },
    createRole() {
      this.createRoleBusy = true
      const payload = {
        name: this.name,
        display_name: this.display_name,
        icon: this.icon,
        description: this.description,
        is_user_role: this.is_user_role,
        is_public: this.is_public,
        is_solution: this.is_solution,
      }
      this.name = null;
      this.display_name = null;
      this.description = null;
      this.icon = null;
      this.is_user_role = true;
      this.is_public = false;
      this.is_solution = false;
      this.$store.dispatch("admin/createRole", {data: payload})
          .then(response => {
            this.createRoleBusy = false
            this.registerToast(this.$toast.success(`Role "${payload.name}" created successfully !`, {timeout: 10000}))
          })
          .catch(error => {
            this.createRoleBusy = false
            this.registerToast(this.$toast.error('Failed to create role. Please retry.', {timeout: 10000}))
          })
          .finally(() => {
            this.closeModal();
          })
    }
  },
};

</script>

<style scoped>
.create-role-btn {
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 45px;

  &:hover {
    background-color: var(--datama-primary);
    box-shadow: 0px 4px 8px rgba(var(--datama-primary-rgb), 0.2);

    &::before {
      content: unset;
    }

    .create-role-text {
      color: white;
    }

    .create-role-icon {
      color: white !important;
    }
  }

  &:focus {
    background-color: #00484A;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

    &::before {
      content: unset;
    }

    .create-role-text {
      color: white;
    }

    .create-role-icon {
      color: white !important;
    }
  }
}

.confirm-create-btn {
  background-color: #16D0B4 !important;
  color: white !important;

  &:hover {
    background-color: #00484A !important;
  }
}
</style>