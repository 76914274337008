import Vue from "vue";
import Vapi from "vuex-rest-api";
import {getApiURL} from "@/utils/getApiURL";
import {developer_mode} from "@/utils/developer_mode";
import store from "@/store";

const user = new Vapi(
    {
        baseURL: getApiURL(),
        state: {
            status: {
                front: true,
                back: true,
                system: {
                    cache: true,
                    core: true,
                    database: true
                }
            },
            issues: [],
            panel: {
                user: [],
                user_roles: [],
                users: [],
                org: [],
                org_roles: [],
                organizations: [],
                org_members: [],
                roles: []
            }
        }
    }).get({
    action: 'status',
    path: '/monitor/status',
    beforeRequest: () => {
        if (developer_mode()) {
            console.info("%cFetching status...", 'background: #222; color: #bada55')
        }
    },
    onSuccess: (state, payload, axios, {data}) => {
        state.status = payload.data.state
        if (developer_mode()) {
            console.info("%cStatus fetched ! ", 'background: #222; color: #bada55')
            console.info(state.status)
        }
    },
    onError: (state, error) => {
        state.status = {
            front: true,
            back: false,
            system: {
                database: false,
                cache: false
            }
        }
        if (developer_mode()) {
            console.info("%cFail to fetch status", 'background: #F00; color: #000')
            console.debug(error)
        }
    },
})
    /**
     * CRUD issues
     */
    .get({
        action: 'issues.fetch',
        path: '/monitor/issues',
        beforeRequest: () => {
            if (developer_mode()) {
                console.info("%cFetching issues...", 'background: #222; color: #bada55')
            }
        },
        onSuccess: (state, payload) => {
            state.issues = payload.data.issues
            if (developer_mode()) {
                console.info("%cIssues fetched ! ", 'background: #222; color: #bada55')
                console.info(state.status)
            }
        },
        onError: (state, error) => {
            state.issues = []
            if (developer_mode()) {
                console.info("%cFail to fetch issues", 'background: #F00; color: #000')
                console.debug(error)
            }
        },
    })
    .post({
        action: 'issues.create',
        path: '/monitor/issues',
        beforeRequest: () => {
            if (developer_mode()) {
                console.info("%cCreating issue...", 'background: #222; color: #bada55')
            }
        },
        onSuccess: (state, payload) => {
            state.issues = payload.data.issues
            if (developer_mode()) {
                console.info("%cIssue created ! ", 'background: #222; color: #bada55')
                console.info(state.status)
            }
        },
        onError: (state, error) => {
            state.issues = []
            if (developer_mode()) {
                console.info("%cFail to create", 'background: #F00; color: #000')
                console.debug(error)
            }
        },
    })
    .patch({
        action: 'issues.update',
        path: '/monitor/issues',
        beforeRequest: () => {
            if (developer_mode()) {
                console.info("%cUpdating issue...", 'background: #222; color: #bada55')
            }
        },
        onSuccess: (state, payload) => {
            state.issues = payload.data.issues
            if (developer_mode()) {
                console.info("%cIssue updated ! ", 'background: #222; color: #bada55')
                console.info(state.status)
            }
        },
        onError: (state, error) => {
            state.issues = []
            if (developer_mode()) {
                console.info("%cFail to update issue", 'background: #F00; color: #000')
                console.debug(error)
            }
        },
    })
    .delete({
        action: 'issues.delete',
        path: '/monitor/issues',
        beforeRequest: () => {
            if (developer_mode()) {
                console.info("%cDeleting issue...", 'background: #222; color: #bada55')
            }
        },
        onSuccess: (state, payload) => {
            state.issues = payload.data.issues
            if (developer_mode()) {
                console.info("%cIssue deleted ! ", 'background: #222; color: #bada55')
                console.info(state.status)
            }
        },
        onError: (state, error) => {
            state.issues = []
            if (developer_mode()) {
                console.info("%cFail to delete issue", 'background: #F00; color: #000')
                console.debug(error)
            }
        },
    }).get({
        action: "getOrganizations",
        path: `/organization/admin/all`,
        queryParams: true,
        beforeRequest: () => {
            store.dispatch('showSpinner', {id: 'admin/getOrganizations', state: true})
        },
        onSuccess: (state, {data}) => {
            state.panel.organizations = data.orgs
            store.dispatch('hideSpinner', {id: 'admin/getOrganizations', state: false})
        },
        onError: () => {
            store.dispatch('hideSpinner', {id: 'admin/getOrganizations', state: false})
        }
    }).get({
        action: "getUsers",
        path: `/user/list`,
        queryParams: true,
        beforeRequest: () => {
            store.dispatch('showSpinner', {id: 'admin/getUsers', state: true})
        },
        onSuccess: (state, {data}) => {
            state.panel.users = data
            store.dispatch('hideSpinner', {id: 'admin/getUsers', state: false})
        },
        onError: () => {
            store.dispatch('errorSpinner', {id: 'admin/getUsers'})
        }
    }).get({
        action: "getMembers",
        path: `/organization/members`,
        queryParams: true,
        beforeRequest: () => {
            store.dispatch('showSpinner', {id: 'admin/getMembers', state: true})
        },
        onSuccess: (state, {data}) => {
            if (data.memberships) {
                state.panel.org_members = data.memberships
            }
            store.dispatch('hideSpinner', {id: 'admin/getMembers', state: false})
        },
        onError: () => {
            store.dispatch('errorSpinner', {id: 'admin/getMembers'})
        }
    }).get({
        action: "getRoles",
        path: `/role/list`,
        queryParams: true,
        beforeRequest: () => {
            store.dispatch('showSpinner', {id: 'admin/getRoles', state: true})
        },
        onSuccess: (state, {data}) => {
            state.panel.roles = data.roles
            store.dispatch('hideSpinner', {id: 'admin/getRoles', state: false})
        },
        onError: () => {
            store.dispatch('errorSpinner', {id: 'admin/getRoles'})
        }
    }).post({
        action: "createRole",
        path: `/role`,
        beforeRequest: () => {
            store.dispatch('showSpinner', {id: 'admin/createRole', state: true})
        },
        onSuccess: () => {
            store.dispatch('admin/getRoles')
            store.dispatch('hideSpinner', {id: 'admin/createRole', state: false})
        },
        onError: () => {
            store.dispatch('errorSpinner', {id: 'admin/createRole'})
        }
    })
    .post({
        action: "impersonateUser",
        path: `/auth/user/impersonate`,
        queryParams: true,
        beforeRequest: () => {
            store.dispatch('showSpinner', {id: 'admin/impersonateUser', state: true})
        },
        onSuccess: () => {
            store.dispatch('admin/getRoles')
            store.dispatch('hideSpinner', {id: 'admin/impersonateUser', state: false})
        },
        onError: () => {
            store.dispatch('errorSpinner', {id: 'admin/impersonateUser'})
        }
    })
    .delete({
        action: "deleteRole",
        path: `/role`,
        queryParams: true,
        beforeRequest: () => {
            store.dispatch('showSpinner', {id: 'admin/deleteRole', state: true})
        },
        onSuccess: () => {
            store.dispatch('admin/getRoles')
            store.dispatch('hideSpinner', {id: 'admin/deleteRole', state: false})
        },
        onError: () => {
            store.dispatch('errorSpinner', {id: 'admin/deleteRole'})
        }
    }).patch({
        action: "editRole",
        path: `/role`,
        beforeRequest: () => {
            store.dispatch('showSpinner', {id: 'admin/updateRole', state: true})
        },
        onSuccess: () => {
            store.dispatch('admin/getRoles')
            store.dispatch('hideSpinner', {id: 'admin/updateRole', state: false})
        },
        onError: () => {
            store.dispatch('errorSpinner', {id: 'admin/updateRole'})
        }
    }).post({
        action: "updateUserRoles",
        path: `/role/user`,
        queryParams: true,
        beforeRequest: () => {
            store.dispatch('showSpinner', {id: 'admin/updateUserRoles', state: true})
        },
        onSuccess: (state, payload, axios, {params}) => {
            store.dispatch('admin/getUserRoles', {params})
            store.dispatch('hideSpinner', {id: 'admin/updateUserRoles', state: false})
        },
        onError: () => {
            store.dispatch('errorSpinner', {id: 'admin/updateUserRoles'})
        }
    }).get({
        action: "getUserRoles",
        path: `/role/user`,
        queryParams: true,
        beforeRequest: () => {
            store.dispatch('showSpinner', {id: 'admin/getUserRoles', state: true})
        },
        onSuccess: (state, {data}) => {
            state.panel.user_roles = data.role_mapping_users
            store.dispatch('hideSpinner', {id: 'admin/getUserRoles', state: false})
        },
        onError: () => {
            store.dispatch('errorSpinner', {id: 'admin/getUserRoles'})
        }
    }).post({
        action: "verifyAdminOtp",
        path: `/auth/admin/otp/check`,
        queryParams: true,
        beforeRequest: () => {
            store.dispatch('showSpinner', {id: 'admin/verifyAdminOtp', state: true})
        },
        onSuccess: (state, {data}) => {
            // state.panel.user_roles = data.role_mapping_users
            store.dispatch('hideSpinner', {id: 'admin/verifyAdminOtp', state: false})
        },
        onError: () => {
            store.dispatch('errorSpinner', {id: 'admin/verifyAdminOtp'})
        }
    }).post({
        action: "generateOTPCode",
        path: `/auth/admin/otp/generate`,
        queryParams: true,
        beforeRequest: () => {
            store.dispatch('showSpinner', {id: 'admin/generateOTPCode', state: true})
        },
        onSuccess: (state, {data}) => {
            // state.panel.user_roles = data.role_mapping_users
            store.dispatch('hideSpinner', {id: 'admin/generateOTPCode', state: false})
        },
        onError: () => {
            store.dispatch('errorSpinner', {id: 'admin/generateOTPCode'})
        }
    }).get({
        action: "checkAdminSecurity",
        path: `/auth/admin/check`,
        queryParams: true,
    }).post({
        action: "updateOrgRoles",
        path: `/role/org`,
        queryParams: true,
        beforeRequest: () => {
            store.dispatch('showSpinner', {id: 'admin/updateOrgRoles', state: true})
        },
        onSuccess: (state, payload, axios, {params}) => {
            store.dispatch('admin/getOrgRoles', {params})
            store.dispatch('hideSpinner', {id: 'admin/updateOrgRoles', state: false})
        },
        onError: () => {
            store.dispatch('errorSpinner', {id: 'admin/updateOrgRoles'})
        }
    }).get({
        action: "getOrgRoles",
        path: `/role/org`,
        queryParams: true,
        beforeRequest: () => {
            store.dispatch('showSpinner', {id: 'admin/getOrgRoles', state: true})
        },
        onSuccess: (state, {data}) => {
            state.panel.org_roles = data.role_mapping_orgs
            store.dispatch('hideSpinner', {id: 'admin/getOrgRoles', state: false})
        },
        onError: () => {
            store.dispatch('errorSpinner', {id: 'admin/getOrgRoles'})
        }
    }).getStore()
// user.getters = {
//     preferences: ({preferences, force_developer_mode, ignore_admin_status, debug}) => (
//         {
//             ...preferences,
//             admin: {
//                 debug,
//                 devMode: force_developer_mode,
//                 userMode: ignore_admin_status
//             }
//         }
//     )
// }
// user.actions.animation = ({ commit }, payload) => {
//     commit("ANIMATION", payload)
// }
//
// user.mutations.ANIMATION = (state, value) => {
//     if (value) {
//         console.info('%cDefine animation state : ' + value, 'background: #222; color: #bada55')
//     }
//     Vue.set(state, 'animation', value)
// }


user.namespaced = true

export default user