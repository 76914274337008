<template>
  <v-card
    v-if="member.user"
    class="w-100"
  >
    <v-card-title class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <div class="ml-3">
          <div class="d-flex align-center">
            <span>{{ member.user.name }}</span>
          </div>
          <div style="font-size: smaller; text-align: left">
            Membership id : {{ member.id }}<br>
            UUID : {{ member.user.uid }}<br>
            email : {{ member.user.email }}<br>
            Member of the org since: {{ new Date(member.created_at).toLocaleDateString() }}<br>
            Datama user since: {{ new Date(member.user.created_at).toLocaleDateString() }}
          </div>
        </div>
      </div>
      <div
        v-if="edit === true"
        style="display: flex; align-items: center;"
      >
        <DeleteMemberModal
          :member="member"
          admin-panel
        />
      </div>
    </v-card-title>
  </v-card>
  <v-card
    v-else
    class="w-100"
  >
    <v-card-title class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <div class="ml-3">
          <div class="d-flex align-center">
            <span>{{ member.name }}</span>
          </div>
          <div style="font-size: smaller; text-align: left">
            uid : {{ member.uid }}<br>
            Email : {{ member.email }}<br>
            Datama user since: {{ new Date(member.created_at).toLocaleDateString() }}
          </div>
        </div>
      </div>
      <div
        style="display: flex; align-items: center;"
      >
        <ManageUserRolesModal
          class="mx-2"
          :user="member"
        />
        <ImpersonateUser
          class="mx-2"
          :selected-user="member"
        />
        <!--        <v-btn-->
        <!--          icon-->
        <!--          class="text-danger shadow-none transparent"-->
        <!--          @click="editUser(member)"-->
        <!--        >-->
        <!--          <v-icon>mdi-pencil-outline</v-icon>-->
        <!--        </v-btn>-->
        <!--        <v-btn-->
        <!--          icon-->
        <!--          class="text-dark shadow-none transparent"-->
        <!--          @click="deleteUser(member)"-->
        <!--        >-->
        <!--          <v-icon>mdi-delete-outline</v-icon>-->
        <!--        </v-btn>-->
      </div>
    </v-card-title>
  </v-card>
</template>
<script>
import ViewAbstract from "@/views/Mixins/ViewAbstract.vue";
import OrgAbstract from "@/components/Home/Org/Mixins/OrgAbstract.vue";
import DeleteMemberModal from "@/components/Home/Org/Modals/DeleteMemberModal.vue";
import ManageUserRolesModal from "@/components/Home/Admin/Modals/ManageUserRolesModal.vue";
import ImpersonateUser from "@/components/Home/Admin/Modals/ImpersonateUser.vue";

export default {
  name: 'MemberCard',
  components: {ImpersonateUser, ManageUserRolesModal, DeleteMemberModal},
  mixins: [
    ViewAbstract,
    OrgAbstract
  ],
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    member: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {
    editUser() {
      //TODO : Add editUser
      this.registerToast(this.$toast.success(`Not available yet.`, {timeout: 3000}))
    },
    deleteUser() {
      //TODO : Add deleteUser
      this.registerToast(this.$toast.success(`Not available yet.`, {timeout: 3000}))
    }
  }
}
</script>