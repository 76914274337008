<template>
  <div class="pa-4">
    <v-btn
      color="orange"
      class="text-yellow shadow-none transparent"
      style="color:white;"
      @click="openModal()"
    >
      <v-icon left>
        mdi-drama-masks
      </v-icon>
      Impersonate
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-center text-h5">
          Are you sure Impersonate {{ user.name }}
        </v-card-title>
        <v-card-text>
          You are about to impersonate the user: {{ user.name }}.<br> While impersonating, you will have access to all their data and permissions. Any actions performed will be logged and attributed to your account. Please proceed with caution.
          <br>
          <v-alert
            type="error"
            dense
            outlined
          >
            Impersonation should be used responsibly. Ensure that you have the necessary permissions to perform this action and that you understand the implications of accessing another user's account."
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green text-white darken-1"
            @click="closeModal"
          >
            No
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            :loading="loading"
            @click="impersonateUser"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OrgAbstract from "@/components/Home/Org/Mixins/OrgAbstract.vue";
import AuthAbstract from "@/components/Security/Mixins/AuthAbstract.vue";
import AdminAbstract from "@/components/Home/Admin/Mixins/AdminAbstract.vue";
import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";

export default {
  name: 'ImpersonateUser',
  components: {},
  mixins: [
    OrgAbstract, AdminAbstract, DataMaLogicAbstract, AuthAbstract
  ],
  props: {
    selectedUser: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading:false,
      dialog: false,
    };
  },
  watch: {},
  methods: {
    openModal(){
      this.dialog = true;
    },
    impersonateUser() {
      this.loading = true
      this.$store.dispatch("admin/impersonateUser", {
          data: {
            uid: this.selectedUser?.uid
          }
        }).then((response) => {
          this.storeImpersonateUser(response.data.auth)
          this.loading = false
          this.dialog = false
        }).catch((err) => {
          this.loading = false
          this.$toast.error(err.toString())
          console.error(err)
      })
    },
    closeModal() {
      this.dialog = false;
    },
  },
};

</script>