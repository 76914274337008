<template>
  <div>
    <h5 class="mb-0">
      Features
    </h5>
    <div class="features-list">
      <div
        v-for="item in sortedItems.items"
        :key="item.rid"
        class="feature"
      >
        <div
          v-if="item"
          class="feature-info"
        >
          <v-icon
            v-if="['prep_enabled', 'compare_analyse', 'impact_analyse'].indexOf(item.name) !== -1"
            v-b-tooltip.hover.top.html="getTooltipContent(item)"
            class="feature-icon"
            :style="item.is_active ? {color: 'var(--datama-primary)'} : {opacity : '.5'} "
          >
            {{ item.icon }}
          </v-icon>
          <span
            v-else
            v-b-tooltip.hover.top.html="getTooltipContent(item)"
            class="feature-icon"
            :style="item.is_active ? {color: 'var(--datama-primary)'} : {opacity : '.5'} "
            v-html="item.icon"
          >
            {{ item.icon }}
          </span>
          <div class="feature-details">
            <h6
              class="ma-0"
              style="white-space: nowrap"
              :style="item.is_active ? '' : {opacity : '.5'} "
            >
              {{ item.display_name }}
            </h6>
          </div>
          <div class="checkbox ml-auto">
            <v-checkbox
              v-if="item.is_active"
              class="mt-0"
              :color="item.is_active ? 'secondary' : ''"
              hide-details
              :readonly="true"
              :input-value="item.is_active"
            />
            <v-icon
              v-else
              v-b-tooltip.hover.top.html="'Let\'s talk !'"
              class="add-button"
              @click="redirectToLetsTalk"
            >
              mdi-open-in-new
            </v-icon>
          </div>
        </div>
      </div>
      <div class="icon-list">
        <v-icon
          v-for="feature in sortedItems.features"
          :key="feature.role_name"
          v-b-tooltip.hover.top.html="getTooltipContent(feature)"
          class="icon-list-icon"
          :style="feature.is_active ? {color: 'var(--datama-primary)'} : {opacity : '.5'} "
        >
          {{ feature.icon }}
        </v-icon>
      </div>
    </div>
  </div>
</template>
<script>
import OrgAbstract from "../../Org/Mixins/OrgAbstract.vue";
import AdminAbstract from "@/components/Home/Admin/Mixins/AdminAbstract.vue";

export default {
  name: 'FeaturesCard',
  components: {},
  mixins: [
    OrgAbstract,
    AdminAbstract
  ],
  props: {
    user: {
      type: Boolean,
      default: false
    },
    org: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      value: [],
      oldValue: [],
      items: null,
    };
  },
  computed: {
    isValueDiff() {
      return !this.areArraysEqual(this.value, this.oldValue);
    },
    sortedItems() {
      const filteredItems = this.items?.filter(item => {
        if (this.$store.state.security.oauth.decoded_token.groups.includes('datama_admin')) {
          return true; // Display all items when admin is true
        } else {
          return item.is_public; // Display only public items when admin is false
        }
      }) || [];
      const items = filteredItems.filter(item => {
        // Case 1: this.org is true, show items where is_user_role is false
        if (this.org === true && this.user === false) {
          return item.is_user_role === false;
        }
        // Case 2: this.user is true, show all items
        else if (this.user === true && this.org === false) {
          return true;
        }
        // Case 3: both this.org and this.user are true, do not show items where is_user_role is true
        else if (this.org === true && this.user === true) {
          return item.is_user_role === false;
        }
        // Default case: if neither this.org nor this.user is true, show based on is_user_role
        else {
          return item.is_user_role;
        }
      });

      const solutions = items
          .map(item => {
            const is_active = this.value.includes(item.rid);
            return {
              ...item,
              is_active
            };
          })
          .filter(item => item.is_solution === true);

      const features = items
          .map(item => {
            const is_active = this.value.includes(item.rid);
            return {
              ...item,
              is_active
            };
          })
          .filter(item => item.is_solution === false);
      const sortedSolutions = solutions.sort((a, b) => {
        if (a.hasFeature && !b.hasFeature) return -1;
        if (!a.hasFeature && b.hasFeature) return 1;

        const aIs_active = this.value.includes(a.rid);
        const bIs_active = this.value.includes(b.rid);
        return bIs_active - aIs_active;
      });

      features.sort((a, b) => b.is_active - a.is_active);

      return {
        items: sortedSolutions,
        features
      };
    }
  },
  mounted() {
    this.fetchRoles()
    if (this.org === true) {
      this.fetchOrgRoles()
    } else if (this.user === true) {
      this.fetchUserRoles()
    }
  },
  methods: {
    getTooltipContent(item) {
      if (item.description) {
        return item.display_name + '<br>' + item.description;
      } else {
        return item.display_name;
      }
    },
    areArraysEqual(array1, array2) {
      if (array1.length !== array2.length) return false;

      // Convert both arrays to sets to ignore order and duplicates
      const set1 = new Set(array1);
      const set2 = new Set(array2);

      if (set1.size !== set2.size) return false;

      // Check if every element in set1 exists in set2
      for (let item of set1) {
        if (!set2.has(item)) return false;
      }

      return true;
    },
    fetchUserRoles() {
      this.$store.dispatch("admin/getUserRoles").then((response) => {
        if (this.user_roles != null) this.value = this.user_roles.map(user_role => user_role.rid)
        this.oldValue = this.value
      })
    },
    fetchOrgRoles() {
      this.$store.dispatch("admin/getOrgRoles").then((response) => {
        if (this.org_roles != null) this.value = this.org_roles.map(org_role => org_role.rid)
        this.oldValue = this.value
      })
    },
    fetchRoles() {
      this.$store.dispatch("admin/getRoles").then(response => {
        this.items = this.roles
      })
    },
    redirectToLetsTalk() {
      window.open("https://datama.io/lets-talk/", '_blank').focus();
    }
  },
};

</script>
<style scoped lang="scss">
.features-list {
  padding-block: 16px;
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.feature:not(.hide) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  width: 100%;
}

.feature:not(.hide) {
  border-bottom: 1px solid #e0e0e0;
}

.feature:last-child,
.feature.hide:last-child {
  border-bottom: none;
}

.feature-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.feature-icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  margin-right: 12px;
  border: var(--border);
  border-radius: 25%;
}

.feature-details {
  max-width: 200px;
}

.feature-badge {
  background-color: #f0f0f0;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.75em;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;

  /* https://stackoverflow.com/questions/57704291/how-to-pass-styles-to-child-component-and-use-it-as-scoped-style-in-vue */
  &::v-deep .v-input--selection-controls__input {
    margin-right: 0;
  }
}

.icon-list {
  display: flex;
  gap: 10px;
}

.icon-list-icon {
  width: 40px;
  height: 40px;
  padding: 25px;
  border: var(--border);
  border-radius: 25%;
  margin-top: 8px;
}
</style>