import workflow, {workflowInitialState} from "../workflow";
import sample, {sampleInitialState} from "../sample";


const prepInitialState = () => ({
    ...sampleInitialState(),
    ...workflowInitialState(true),
    differences: []
})

export default {
    namespaced: true,
    name: 'prep',
    state: prepInitialState(),
    mutations: {
        ...sample.mutations,
        ...workflow.mutations,
        ADD_BLOCK_DIFFERENCE(state, payload) {
            if (payload.id) {
                let index = state.differences.map(d => d.id).indexOf(payload.id);
                if (index === -1) {
                    state.differences.push(payload);
                }
            }
        },
        REMOVE_BLOCK_DIFFERENCE(state, id) {
            let index = state.differences.map(d => d.id).indexOf(id);
            if (index > -1) {
                state.differences.splice(index, 1)
            }
        },
        RESET_INITIAL_STATE(state)  {
            Object.assign(state, prepInitialState())
        }
    },
    actions: {
        ...sample.actions,
        ...workflow.actions,
        addBlockDifference({ commit }, payload) {
            commit("ADD_BLOCK_DIFFERENCE", payload)
        },
        removeBlockDifference({ commit }, payload) {
            commit("REMOVE_BLOCK_DIFFERENCE", payload)
        },
        resetInitialState ({ commit })  {
            commit('RESET_INITIAL_STATE');
        }
    },
    getters: {
        ...sample.getters,
        ...workflow.getters,
    }
}