<template>
  <div>
    <button
      @click="close"
    >
      &times;
    </button>
    <button
      class="report-bug-error-btn"
      @click.stop="showFeedback"
    >
      <i 
        class="fas fa-bug"
        aria-hidden="true"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: "ToastCloseReportIssue",
  components: {
  },
  data(){
    return {
      
    }
  },
  methods: {
    close(){
      this.$emit('close-toast')
    },
    showFeedback(){
      try {
        const el = document.getElementById("feedback-btn-component");
        el.classList.add("feedback-btn-component-expanded");
        el.click()  
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style>
.report-bug-error-btn{
  /* margin-top:100%; */
  position:absolute;
  left:-20px;
  color:currentColor;
  line-height:9px;
  top:0;
}
.report-bug-error-btn i:hover{
/* background-color:gray; */
  color:red;
}

.report-bug-error-btn i{
  font-size:10px;
  /* color:gray; */
  /* padding:10px; */
  /* border-radius:100%; */
  /* border:1px dashed lightgray; */
}
</style>
