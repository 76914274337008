<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      sm="12"
    >
      <div
        class="d-flex align-center justify-end"
      >
        <v-select
          v-model="value"
          :items="items"
          chips
          item-value="rid"
          item-text="name"
          label="Roles"
          multiple
          :menu-props="{ maxHeight: 225, offsetY: true, overflowY: true }"
        >
          <template #item="{ item, attrs, on }">
            <v-list-item
              v-slot="{ active }"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
        <v-btn
          v-if="user"
          class="bg-primary shadow-none ml-2"
          :disabled="!isValueDiff"
          @click="updateUserRoles"
        >
          Submit
        </v-btn>
        <v-btn
          v-else-if="org"
          class="bg-primary shadow-none ml-2"
          :disabled="!isValueDiff"
          @click="updateOrgRoles"
        >
          Submit
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import OrgAbstract from "../../Org/Mixins/OrgAbstract.vue";
import AdminAbstract from "../Mixins/AdminAbstract.vue";

export default {
  name: 'RolesSelect',
  components: {},
  mixins: [
    OrgAbstract, AdminAbstract

  ],
  props: {
    user: {
      type: Object,
      default: null
    },
    org: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      value: [],
      oldValue: [],
      items: null
    };
  },
  computed: {
    isValueDiff() {
      return !this.areArraysEqual(this.value, this.oldValue);
    },
  },
  mounted() {
    this.fetchRoles()
    if (this.user == null && this.org != null) {
      this.fetchOrgRoles()
    } else {
      this.fetchUserRoles()
    }
  },
  methods: {
    areArraysEqual(array1, array2) {
      if (array1.length !== array2.length) return false;

      // Convert both arrays to sets to ignore order and duplicates
      const set1 = new Set(array1);
      const set2 = new Set(array2);

      if (set1.size !== set2.size) return false;

      // Check if every element in set1 exists in set2
      for (let item of set1) {
        if (!set2.has(item)) return false;
      }

      return true;
    },
    fetchUserRoles() {
      this.$store.dispatch("admin/getUserRoles", {params: {uid: this.user.uid}}).then((response) => {
        if (this.user_roles != null) this.value = this.user_roles.map(user_role => user_role.rid)
        this.oldValue = this.value
      })
    },
    fetchOrgRoles() {
      this.$store.dispatch("admin/getOrgRoles", {params: {oid: this.org.oid}}).then((response) => {
        if (this.org_roles != null) this.value = this.org_roles.map(org_role => org_role.rid)
        this.oldValue = this.value
      })
    },
    fetchRoles() {
      this.$store.dispatch("admin/getRoles").then(response => {
        this.items = this.roles
      })
    },
    updateUserRoles() {
      this.$store.dispatch("admin/updateUserRoles", {data: this.value, params: {uid: this.user.uid}})
    },
    updateOrgRoles() {
      this.$store.dispatch("admin/updateOrgRoles", {data: this.value, params: {oid: this.org.oid}})
    },
  },
};

</script>