<template>
  <div
    class="datama-workbook-name"
    @click.stop="open"
  >
    <span
      @click.stop="open"
    >
      {{ flow.name }}
    </span>
    <v-icon
      v-if="canBeOpen"
      v-b-tooltip.hover.top="'Open in new tab'"
      class="'open'"
      small
      dense
      @click.stop="e => open(e, true)"
    >
      mdi-open-in-new
    </v-icon>
    <v-icon
      v-if="isExperimental && flow.hash"
      v-b-tooltip.hover.top="'Pin this flow'"
      :class="favoriteClass"
      small
      dense
      @click.stop="toggleFavorite(flow.hash)"
    >
      {{ pinned['workbook.' + flow.hash] ? 'mdi-star' : 'mdi-star-outline' }}
    </v-icon>
  </div>
</template>
<script>
import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";

export default {
  name: 'WorkbookNameLink',
  mixins: [DataMaLogicAbstract],
  props: {
    flow: {
      type: Object,
      default: null
    }
  },
  computed: {
    favoriteClass() {
      let classes = ['favorite'];
      if (this.pinned['workbook.' + this.flow?.hash]) {
        classes.push('active')
      }
      return classes;
    },
    canBeOpen() {
      return this.flow?.hash && this.flow?.solutions
    }
  },
  methods: {
    open(event, newTab=false) {
      event.preventDefault();
      if (this.canBeOpen) {
        let solution = this.flow?.solutions?.default || 'prep';
        if (newTab) {
          window.open(window.location.protocol + '//' + window.location.host + '/' + (this.anonymous ? 'demo' : 'a') + '/core/' + solution + '?flow=' + this.flow.hash, '_blank')
        } else {
          this.$router.push({
            name: this.namespace + ':' + solution,
            query: {flow: this.flow.hash}
          })
        }
      }
    }
  }
}
</script>
<style lang="scss">
@import "@/theme/config/_colors.scss";
#app {
  .datama-workbook-name {
    & .favorite,
    & .open {
      opacity: 0;
      transition: .1s ease-in;
      &.active {
        color: $datayellow;
        opacity: 1;
      }
    }
    &:hover .favorite,
    &:hover .open {
      opacity: 1;
      transition: .1s ease-out;
    }
  }
}
</style>