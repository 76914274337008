<script>
export default {
  name: 'OrgAbstract',
  props: {},
  data() {
    return {}
  },
  computed: {
    isOrgLoading() {
      return this.$store.state.spinners.filter(({id}) => id === 'organization/getOrganization')[0] != null && this.$store.state.spinners.filter(({id}) => id === 'organization/getOrganization')[0].state === true
    },
    areOrgsLoading() {
      return this.$store.state.spinners.filter(({id}) => id === 'organization/getOrganizations')[0] != null && this.$store.state.spinners.filter(({id}) => id === 'organization/getOrganizations')[0].state === true
    },
    isOrgCreated() {
      return this.$store.state.spinners.filter(({id}) => id === 'organization/createOrganization')[0] != null && this.$store.state.spinners.filter(({id}) => id === 'organization/createOrganization')[0].state === true
    },
    areOrgMembersLoading() {
      return this.$store.state.spinners.filter(({id}) => id === 'organization/getMembers')[0] != null && this.$store.state.spinners.filter(({id}) => id === 'organization/getMembers')[0].state === true
    },
    isOrgUsageLoading() {
      return this.$store.state.spinners.filter(({id}) => id === 'organization/getOrgUsage')[0] != null && this.$store.state.spinners.filter(({id}) => id === 'organization/getOrgUsage')[0].state === true
    },
    isOrgAdmin() {
      if (this.$store.state.organization && this.membership !== null && this.$store.state.organization.org !== null) {
        return this.membership.is_admin && this.$store.state.organization.org.oid !== null
      }
      return false;
    },
    membership() {
      return this.$store.state.organization.membership;
    },
    orgMembers() {
      return this.$store.state.organization.org_members;
    },
    sharedFlow() {
      return this.$store.state.organization.shared_flow;
    },
    orgUsage() {
      return this.$store.state.organization.org_usage;
    },
    organizations() {
      return this.$store.state.organization.organizations;
    },
    credentials() {
      return this.$store.state.organization.tokens
    },
    shared_credentials() {
      return this.$store.state.organization.shared_credentials
    },
    hasOrganization() {
      return !(typeof this.organization === "function") && (typeof this.organization?.name === "string")
    },
  },
  methods: {
    async checkSharedConnection(service) {
      await this.$store.dispatch("organization/getSharedCredentials")
      if (service !== "sql-connector") {
        return this.shared_credentials.find(shared_credential => shared_credential.token.service === service);
      } else {
        return this.shared_credentials.find(shared_credential => shared_credential.token.service.startsWith('sql_'));
      }
    },
  },
}
</script>