<template>
  <div>
    <v-btn
      icon
      class="text-danger shadow-none transparent"
      @click="openModal()"
    >
      <v-icon>mdi-pencil-outline</v-icon>
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card
        class="datama-dialog"
      >
        <v-card-title>
          <span>
            Edit Role
          </span>
          <v-icon
            class="transparent shadow-none"
            @click="closeModal"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="name"
            label="Role name"
            class="mb-8"
            hide-details
          />
          <v-text-field
            v-model="display_name"
            label="Display name"
            class="mb-8"
            hide-details
          />
          <v-text-field
            v-model="description"
            label="Usage"
            class="mb-8"
            hide-details
          />
          <v-text-field
            v-model="icon"
            label="Icon"
            class="mb-8"
            hide-details
          />
          <v-switch
            v-model="is_public"
            label="Public"
          />
          <v-switch
            v-model="is_user_role"
            label="For User"
          />
          <v-switch
            v-model="is_solution"
            label="Is Solution"
          />
          <InfoMessage
            icon="mdi mdi-list-box-outline"
            message="Edit role to change name or description."
            styles="color:grey;display:block;line-height: 20px;font-size: 14px;"
            class="mb-4 text-left"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            class="mr-2 shadow-none transparent"
            @click="closeModal"
          >
            Cancel
          </v-btn>
          <v-btn
            class="bg-primary shadow-none"
            :loading="editRoleBusy"
            @click="editRole"
          >
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataMaLogicAbstract from "@/components/Structure/Mixins/DataMaLogicAbstract.vue";
import OrgAbstract from "../../Org/Mixins/OrgAbstract.vue";
import AdminAbstract from "../Mixins/AdminAbstract.vue";
import InfoMessage from "@/components/Core/Structure/Misc/InfoMessage.vue";

export default {
  name: 'EditRoleModal',
  components: {InfoMessage},
  mixins: [
    DataMaLogicAbstract, OrgAbstract, AdminAbstract

  ],
  props: {
    role: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      name: this.role.name,
      display_name: this.role.display_name,
      description: this.role.description,
      icon: this.role.icon,
      is_user_role: this.role.is_user_role,
      is_public: this.role.is_public,
      is_solution: this.role.is_solution,
      dialog: false,
      editRoleBusy: false
    };
  },
  watch: {
    role: {
      handler(newValue) {
        this.name = newValue.name;
        this.display_name = newValue.display_name;
        this.icon = newValue.icon;
        this.description = newValue.description;
        this.is_user_role = newValue.is_user_role;
        this.is_public = newValue.is_public;
        this.is_solution = newValue.is_solution;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    openModal() {
      this.dialog = true;
    },
    closeModal() {
      this.name = this.role.name
      this.display_name = this.role.display_name
      this.icon = this.role.icon
      this.description = this.role.description
      this.is_user_role = this.role.is_user_role
      this.is_public = this.role.is_public
      this.is_solution = this.role.is_solution
      this.dialog = false;
    },
    editRole() {
      this.editRoleBusy = true
      const payload = {
        rid: this.role.rid,
        name: this.name,
        display_name: this.display_name,
        icon: this.icon,
        description: this.description,
        is_user_role: this.is_user_role,
        is_public: this.is_public,
        is_solution: this.is_solution
      }
      this.$store.dispatch("admin/editRole", {data: payload})
          .then(response => {
            this.editRoleBusy = false
            this.registerToast(this.$toast.success(`Role "${payload.name}" edited successfully !`, {timeout: 10000}))
          })
          .catch(error => {
            this.name = this.role.name
            this.display_name = this.role.display_name
            this.icon = this.role.icon
            this.description = this.role.description
            this.is_user_role = this.role.is_user_role
            this.is_public = this.role.is_public
            this.is_solution = this.role.is_solution
            this.editRoleBusy = false
            this.registerToast(this.$toast.error('Failed to edit role. Please retry.', {timeout: 10000}))
          })
          .finally(() => {
            this.closeModal();
          })
    }
  },
};

</script>